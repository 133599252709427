import React, { useState, useEffect, useRef } from 'react'
import { FaHippo } from 'react-icons/fa';
import Groups from './components/Groups'
import MessageList from './components/MessageList'
import MessageForm from './components/MessageForm'
import Login from './components/Login'
import Logout from './components/Logout'
import messageService from './services/message'
import groupService from './services/group'
import UseInterval from './hooks/UseInterval'
//import styled from 'styled-components'
// import styled components
import Header from './components/Header'
import LeftColumn from './components/LeftColumn'
import MiddleColumn from './components/MiddleColumn'
import Navbar from './components/Navbar'
import Titlebar from './components/Titlebar'
//const BASE_URL = 'http://localhost:8888/trailway/api/public/index.php/'
const BASE_URL = 'https://trailway.fi/api/public/index.php/'


const App = () => {

  //const BASE_URL = 'http://localhost:8888/trailway/api/public/index.php/'
  const [user, setUser] = useState(null) // Current user
  const [messages, setMessages] = useState([]); // Messages list
  const [groups, setGroups] = useState([]); // Groups list
  const [group, setGroup] = useState(null); // Active group

  // While entering page check if there is user data on localstorage
  useEffect(() => {
    const loggedUserJSON = window.localStorage.getItem('loggedTWAppUser')
    if (loggedUserJSON) {
      const user = JSON.parse(loggedUserJSON)
      setUser(user)
      // Pass token to API services
      messageService.setToken(user.token)
      groupService.setToken(user.token)
      // This was not originally here...
      //loadData();
    }
  }, [])

  // Fetch data from API
  const loadData = async() => {
    try {
      console.log('Data fetched from API for user:', user)
      const fetchedGroups = await groupService.getByUser(BASE_URL)
      console.log('Groups: ', fetchedGroups)
      setGroups(fetchedGroups)
      // Use first group as initial group to be selected
      setGroup(fetchedGroups[0])
      const messages = await messageService.getByGroup(BASE_URL, fetchedGroups[0]._id)
      console.log('Messages: ', messages)
      setMessages(messages)
    } catch (exception) {
      console.log('error')
    }
  }

  // Load messages via REST API when user is set
  useEffect( () => {
    console.log('useEffect: user updated - fetch data from API...')
    //loadMessages();
    loadData()

  }, [user]);

  // Load messages via REST API when user is set
  useEffect( () => {
    console.log('useEffect: group changed - fetch messages from API...')
    loadMessages();
    //loadData()

  }, [group]);

  // Check new messages in 300 sec (5min) intervals
  UseInterval(async() => {
    console.log('New scheduled fetch...')
    loadMessages()
    //loadData()
  }, 300000)

  // ref: https://medium.com/thecodefountain/fetch-api-data-using-useeffect-react-hook-465809ca12c6
  const loadMessages = async() => {
    try {
      console.log('Active group: ', group)
      const response = await messageService.getByGroup(BASE_URL, group._id)
      //const data = await response.json()
      console.log('Messages fetched from API:')
      console.log(response)
      setMessages(response)
    } catch (exception) {
      console.log('error')
    }
  }

  // ref: https://medium.com/thecodefountain/fetch-api-data-using-useeffect-react-hook-465809ca12c6
  const loadGroups = async() => {
    try {
      const response = await groupService.getByUser()
      //const data = await response.json()
      console.log('App.js: Groups fetched from API:')
      console.log(response)
      setGroups(response)
      console.log('App.js: groups ',groups)
    } catch (exception) {
      console.log('error')
    }
  }

  // Reference to container
  const container = useRef(null);

  const handleScroll = () => {
    // Print current scroll value
    //console.log(container.current.scrollTop)
    if (container.current.scrollHeight-container.current.clientHeight !== container.current.scrollTop) return;
    console.log('Scrolled to bottom - fetching more items...');
    //console.log('Newest message: ', messages.pop()['_id'])
    //console.log(group)
    loadMessages()
    //loadData()
  }

  const containerStyle = {
    /*display: 'flex',*/ // testing flexbox
  }

  const logoutStyle = {
    display: 'inline-block',
    float: 'right',
    //color: 'white',
    paddingRight: 10
  }

//{user === null ? <div></div> : <Groups user={user} setGroup={setGroup}/>}

  return (
    <div>
      {user !== null &&
      <LeftColumn>
        <Titlebar><Header>Collap</Header> <span>by Trailway</span> <FaHippo/></Titlebar>
        {user !== null && <div style={{fontSize:'small', color:'#666', paddingTop:10, paddingBottom:10, paddingLeft:5}}>{user.name}</div>}
        {group === null ? <div></div> : <Groups activeGroup={group} groups={groups} setGroup={setGroup}/>}
      </LeftColumn>
      }
      <MiddleColumn onScroll={handleScroll} ref={container}>
        <Navbar>
          <div>
          {group !== null &&
            <div style={{display:'inline-block'}}>
              <span style={{color:'#f4511e'}}>&laquo;</span> <span>{group.name}</span>
            </div>
          }
            <div style={{display:'inline-block', float:'right'}}>
              <span style={logoutStyle}><Logout user={user} setUser={setUser}/></span>
            </div>
        </div>
        </Navbar>
        {user === null && <Login BASE_URL={BASE_URL} setUser={setUser}/>}
        {user !== null &&
           <div>
             <MessageList messages={messages} setMessages={setMessages}/>
             <MessageForm BASE_URL={BASE_URL} group={group} messages={messages} setMessages={setMessages}/>
           </div>
         }
      </MiddleColumn>
    </div>
  )
}
// <p>Logged in: {JSON.parse(user).name}</p>
export default App
