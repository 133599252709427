import React from 'react'
import Button from './Button'

const Logout = ({user, setUser}) => {

  /*
  const LogoutButtonStyle = {
    backgroundColor: '#f4511e',
    color: 'white',
    fontSize: 12,
    padding: 8,
    borderRadius: 10,
    border: 'none',
    borderColor: 'white'
    }
  */
  const handleLogout = (event) => {
    event.preventDefault()
    console.log('logging out '.user)
    setUser(null);
    window.localStorage.removeItem('loggedTWAppUser')
    console.log('logged out')
  }

  return (
      <Button onClick={handleLogout}>Logout</Button>
  )

}

export default Logout
