import axios from 'axios'

//const API_URL = 'http://localhost:8888/trailway/api/public/index.php/messages'
//const API_URL = 'https://trailway.fi/api/public/index.php/messages'

let token = null

const setToken = newToken => {
  token = `bearer ${newToken}`

}

const create = async (BASE_URL, newMessage) => {

  const API_URL = BASE_URL+'messages'

  const config = {
    headers: { Authorization: token },
  }

  const response = await axios.post(API_URL, newMessage, config)
  //console.log(response.data)
  return response.data
}

const getByGroup = async (BASE_URL, groupId) => {

  const API_URL = BASE_URL+'messages'

  const config = {
    headers: { Authorization: token },
  }

  const response = await axios.get(API_URL+'/groups/'+groupId, config)
  //console.log(response.data)
  //return request.then(response => response.data)
  return response.data
}

export default { create, setToken, getByGroup }
