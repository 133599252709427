import styled from 'styled-components'

const Button = styled.button`
  background-color: #f4511e;
  color: white;
  font-size: 12px;
  padding: 8px;
  border-radius: 10px;
  border: none;
  border-color: white;
  &:hover {
    background-color: #ed410c;
  }
`

/*
  const Button = styled.button ({
    backgroundColor: '#f4511e',
    color: 'white',
    fontSize: 12,
    padding: 8,
    borderRadius: 10,
    border: 'none',
    borderColor: 'white',

    &:hover {
      backgroundColor: 'blue'
    }
  })
*/

export default Button
