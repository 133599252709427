import styled from 'styled-components'

const Titlebar = styled.div`
  //background-color: CornFlowerBlue;
  background-color: #ebc340;
  font-family: Tahoma;
  //marginTop: '1rem',
  //position: fixed;
  //top: 0;
  //left: 0,
  width: 100%;
  padding: 0;
  padding-bottom: 5px;
  padding-top: 5px;
  display: block;
  @media screen and (min-width: 560px) {
    width: 100%;
    font-size: calc(16px + 0.2vw);
  }
`
export default Titlebar
