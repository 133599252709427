import styled from 'styled-components'

const MiddleColumn = styled.div`
  position: fixed;
  /*width: 100%;*/
  top: 0;
  bottom: 0;
  /*left: 0,*/
  right: 0;
  overflow-Y: scroll;
  overflow-X: hidden;
  font-size: 20px;
  /*font-family: Tahoma;*/
  display: block;
  /*border-left: 5px solid CornFlowerBlue;*/

  @media screen and (min-width: 560px) {
    width: 60%;
    font-size: calc(16px + 0.2vw);
  }
`
export default MiddleColumn
