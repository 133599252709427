import React, { useState } from 'react'
import { IoSendSharp } from 'react-icons/io5';
import styled from 'styled-components'
import messageService from '../services/message'

const Form = styled.div`
  margin-top: 1rem;
  /*padding: 1rem;*/
  background-color: #ebc340;
  /*
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block;
  */
  /*width: 100%;*/
  bottom: 0;
  right: 0;
  position: fixed;
  /*display: inline-block;*/
  padding: 8px 0px 8px 0px;
  width: 100%;
  @media screen and (min-width: 560px) {
    width: 60%;
  }
`

const Input = styled.input`
  width: 80%;
  //size: '80%',
  margin: 5px;
  height: 30px;
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 0;
  margin-top: 0;
  border: 0;
  background-color: 's';
  resize: none;
  font-family: 'Verdana';
  font-size: calc(12px + 0.5vw);
  /*display: inline-block;*/
  flex-grow: 1;
  &:hover {
    /*border: 1px solid #f4511e;*/
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
`

const SendButton = styled.button`
  color: #f4511e;
  background-color: #ebc340;
  border: 0;
  font-size: x-large;
  padding-left: 8px;
  &:hover {
    color: #d53b0a;
  }
`

const MessageForm = ({BASE_URL, group, messages, setMessages}) => {

  const [message, setMessage] = useState('');

  const formRowStyle = {
    width: '100%',
    display: 'inline-block',
    //display: 'flex',
    alignItems: 'center',
    padding: 5
  }
  /*
  const addButtonStyle = {
    backgroundColor: '#f4511e',
    //backgroundColor: 'rgb(235, 195, 64)',
    color: 'white',
    fontSize: 32,
    borderRadius: 10,
    border: 'none',
    marginLeft: 8,
    display: 'inline-block'
  }
  */
  const addMessage = async (event) => {
    event.preventDefault()
    console.log(event.target)
    const messageObject = {
      content: message,
      //timestamp: new Date().toISOString(),
      groupId: group._id,
      important: false,
      visible: true
    }

    try {
      // Try to add bot capability :o
      if(message[0]==='/') {
        console.log('bot command: ', message)
      }
      const response = await messageService.create(BASE_URL, messageObject)
      //const data = await response.json()
      console.log(response)
      setMessages(messages.concat(response)) // Add received message to view
      setMessage('') // Clear form
    } catch (exception) {
      console.log('error')
    }
  }


  const handleMessageChange = (event) => {
    setMessage(event.target.value)
  }

  // Input used to be textarea...
  /*
  <textarea
    style={inputBoxStyle}
    value={message}
    onChange={handleMessageChange}
    placeholder="Kirjoita uusi viesti..."
  />
  */
  return (
    <Form>
      <div style={formRowStyle}>
        <form onSubmit={addMessage} style={{display:'flex'}}>
          <Input
            value={message}
            onChange={handleMessageChange}
            placeholder="Lisää uusi aihe..."
          ></Input>
        {message !== '' && <SendButton type="submit"><IoSendSharp/></SendButton>}
        </form>
      </div>
    </Form>
  )
}
/*{message !== '' && <button style={addButtonStyle} type="submit">&raquo;</button>}*/
export default MessageForm
