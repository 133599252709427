import styled from 'styled-components'

// Styled Components
const Header = styled.span`
  font-family: Tahoma;
  text-align: center;
  color: #f4511e;
  font-size: 22px;
  padding-left: 10px;
`
export default Header
