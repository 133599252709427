import React, { useState } from 'react'
import loginService from '../services/login'
import messageService from '../services/message'
import groupService from '../services/group'

const Login = ({BASE_URL, setUser}) => {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = async (event) => {
    event.preventDefault()
    //console.log(username)
    //console.log(password)
    try {
      const user = await loginService.login({
        BASE_URL, username, password
      })
      //console.log('logged in: ', user)
      window.localStorage.setItem(
        'loggedTWAppUser', JSON.stringify(user)
      )
      setUser(user)
      messageService.setToken(user.token)
      groupService.setToken(user.token)
      //setUser(JSON.stringify(user))
      //setUsername('')
      //setPassword('')
      console.log('logged in: ', user)
    } catch (exception) {
      console.log('Wrong credentials')
      //setErrorMessage('wrong credentials')
      setTimeout(() => {
        //setErrorMessage(null)
      }, 5000)
    }
  }

  return (
    <div>
    <h2>Kirjaudu palveluun</h2>
    <form onSubmit={handleLogin}>
      <div>
        Sähköposti
          <input
          type="text"
          value={username}
          name="Username"
          onChange={({ target }) => setUsername(target.value)}
        />
      </div>
      <div>
        Salasana
          <input
          type="password"
          value={password}
          name="Password"
          onChange={({ target }) => setPassword(target.value)}
        />
      </div>
      <button type="submit">login</button>
    </form>
    </div>
  )

}

export default Login
