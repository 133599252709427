import axios from 'axios'

//const API_URL = 'http://localhost:8888/trailway/api/public/index.php/login'
//const API_URL = 'https://trailway.fi/api/public/index.php/login'

const login = async ({BASE_URL, username, password}) => {

  const API_URL = BASE_URL+'login'

  console.log(username)
  const response = await axios.post(API_URL, {username, password})
  console.log(response.data)
  return response.data
}

// Following does not work
/*
const login = async ({username, password}) => {

  const body = {
    "username" : username,
    "password" : password
  }

  const response = await fetch(API_URL,
  {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify(body)
  })
  console.log(response.json())
  return response.json()
}
*/
// Send to API endpoint
// Following works but should be acync?
// Now setUser method pasted since return from login function does not work well
/*
const login = ({username, password, setUser}) => {

  const body = {
    "username" : username,
    "password" : password
  }

  fetch(API_URL,
  {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(body)
  })
  //.then(function(res){ console.log(res) })
  .then(response => response.json())
  .then(response => {
    console.log("Data received", response)
    setUser(response.name)
  })
  .catch(function(res){ console.log(res) })
}
*/
export default {login}
