import styled from 'styled-components'

const Navbar = styled.div`
  background-color: CornFlowerBlue;
  font-family: Tahoma;
  //marginTop: '1rem',
  position: fixed;
  top: 0;
  //left: 0,
  width: 100%;
  padding: 0;
  padding-bottom: 5px;
  padding-top: 5px;
  display: block;
  @media screen and (min-width: 560px) {
    width: 60%;
    font-size: calc(16px + 0.2vw);
  }  
`
export default Navbar
