import styled from 'styled-components'

const LeftColumn = styled.div`
  position: fixed;
  width: 40%;
  top: 0;
  bottom: 0;
  background-color: #f0f8ff;
  /*border-left: 5px solid #6495ed;*/
  /*background-color: CornFlowerBlue;*/

  @media screen and (max-width: 560px) {
    display: none;
  }
`
export default LeftColumn
