import Message from './Message'
const MessageList = ({messages, setMessages}) => {

  const messagesStyle = {
    backgroundColor: 'CornflowerBlue',
    //borderBottomLeftRadius: 10,
    //borderBottomRightRadius: 10,
    padding: 10,
    display: 'inlineBlock',
    paddingBottom: '50px'
  }

  //const API_URL = 'https://trailway.fi/api/public/index.php/messages';
  //const API_KEY = '';
  //const APP_ID = '';

  return (
    <div style={messagesStyle}>
      {
        messages.map(
          (message) =>
          <Message key={message._id} senderName={message.users[0].first_name+" "+message.users[0].last_name} type={message.type} content={message.content} created={message.created} visible={message.visible} />
        )
      }

    </div>
  )
}

export default MessageList
