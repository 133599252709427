import styled from 'styled-components'
import { RiTaskLine } from "react-icons/ri";

const MessageBox = styled.div`
  /*font-family: 'Tahoma';*/
  /*font-size: 16;*/
  border: 1px;
  padding: 10px;
  margin-bottom: 8px;
  background-color: #f0f8ff;
  border-radius: 10px;
  box-shadow: 2px 2px #006bc9;
  &:hover {
    background-color: #ffffff;
  }
`

const TaskBox = styled.div`
  /*font-family: 'Tahoma';*/
  /*font-size: 16;*/
  border: 1px;
  padding: 10px;
  margin-bottom: 8px;
  background-color: #fff0f8;
  border-radius: 10px;
  box-shadow: 2px 2px #666063;
  &:hover {
    background-color: #ffffff;
  }
`

// Ref: https://stackoverflow.com/questions/7693224/how-do-i-right-align-div-elements
const MessageIcon = styled.div`
  text-align: left;
  font-size: 10px;
  flex-grow: 1;
  /*display : inline-block;*/
`
const Date = styled.div`
  text-align: right;
  font-size: 10px;
  color: gray;
  /*display : inline-block;*/
  /*float: right*/
`

const Message = ({senderName, type, content, created, visible}) => {

  const messageBoxDisabled = {
    /*font-family: 'Tahoma';*/
    //fontSize: 16,
    border: 1,
    padding: 10,
    marginBottom: 8,
    backgroundColor: 'lightgray',
    color: 'gray',
    borderRadius: 10,
    //boxShadow: '2px 2px DarkSlateGray'
  }

  const senderStyle = {
    textAlign: 'left',
    fontSize: 12,
    color: '#f4511e'
  }

  const dateStyle = {
    textAlign: 'right',
    fontSize: 10,
    color: 'gray',
  }

  const editButtonStyle = {
    float: 'right',
    fontSize: 24,
    color: 'red'
  }

  const date = created.split('T')[0] // Split the date part only

  //<MessageIcon>{date}</MessageIcon>
  if(visible) {
    if(type==='task') {
      return (
        <TaskBox>
          <span style={senderStyle}>{senderName}</span><span style={editButtonStyle}>&#711;</span>
          <div>{content}</div>
          <div style={{display:'flex'}}><MessageIcon><RiTaskLine/></MessageIcon><Date>{date}</Date></div>
        </TaskBox>
      )
    } else {
      return (
        <MessageBox>
          <span style={senderStyle}>{senderName}</span><span style={editButtonStyle}>&#711;</span>
          <div>{content}</div>
          <div style={dateStyle}>{date}</div>
        </MessageBox>
      )
    }
  } else {
    return (
      <div style={messageBoxDisabled}>
        <span style={senderStyle}>{senderName}</span><span style={editButtonStyle}>&#711;</span>
        <div align='center'>&#128683;<i>Viesti on poistettu</i></div>
        <div style={dateStyle}>{date}</div>
      </div>
    )
  }
}
export default Message
