import axios from 'axios'

//const API_URL = 'http://localhost:8888/trailway/api/public/index.php/groups'
//const API_URL = 'https://trailway.fi/api/public/index.php/messages'

let token = null

const setToken = newToken => {
  token = `bearer ${newToken}`
}

const getByUser = async (BASE_URL) => {

  const API_URL = BASE_URL+'groups'

  const config = {
    headers: { Authorization: token },
  }

  // Get groups for identified user
  const response = await axios.get(API_URL+'/user/', config)
  //console.log(response.data)
  //return request.then(response => response.data)
  return response.data
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { getByUser, setToken }
